import { graphql, HeadProps, PageProps } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import SectionTitle from "../components/SectionTitle";
import { EDITION_6TH } from "../constants";
import { useGetCommentsQuery } from "../features/comment/commentApiSlice";
import CommentCreateForm from "../features/comment/CommentCreateForm";
import CommentParent from "../features/comment/CommentParent";
import { comments6thEdition } from "../features/comment/utils";
import Footer from "../features/footer/Footer";
import Navbar from "../features/navbar/Navbar";
import Seo from "../features/seo/Seo";
import Answer from "../features/solution/Answer";
import Heading from "../features/solution/Heading";
import VideoArea from "../features/solution/media-chrome/VideoArea";
import Menu from "../features/solutionMenu/Menu";
import { chapterSections6thEdition } from "../features/solutionMenu/utils";
import { useCurrentUserQuery } from "../features/user/userApiSlice";
import {
  ChapterNode,
  ChapterSection,
  ChapterTerm,
  DrupalComment,
  DrupalNodeSolutionBase,
} from "../types";
import * as seoUtils from "../features/seo/utils";
import { JsonLd } from "react-schemaorg";

export type DrupalNodeSolution6thEdition = DrupalNodeSolutionBase & {
  comment_node_6th_edition_video: {
    cid: number;
    last_comment_timestamp: number;
    comment_count: number;
  };
  relationships: {
    comment__comment_node_6th_edition_video: DrupalComment[];
    field_6th_edition_chapter: ChapterTerm & {
      relationships: {
        node__6th_edition_video: ChapterNode[];
        taxonomy_term__6th_edition_section: {
          field_section_name: string;
          field_section_number: string;
          drupal_internal__tid: number;
          weight: number;
          relationships: {
            node__6th_edition_video: {
              drupal_internal__nid: number;
              field_duration: number;
              field_problem: number;
              path: {
                alias: string;
              };
            }[];
          };
        }[];
      };
    };
  };
};

interface Solution6thEditionData {
  node6thEditionVideo: DrupalNodeSolution6thEdition;
}

const Solution6thEdition = (props: PageProps<Solution6thEditionData>) => {
  const { data: currentUser } = useCurrentUserQuery();
  const node = props.data.node6thEditionVideo;
  const { data: commentsJsonapi, isFetching: isFetchingComments } =
    useGetCommentsQuery({
      contentType: "6th_edition_video",
      uuid: node.drupal_id,
    });
  const sections = useMemo(
    () => chapterSections6thEdition(node),
    [node.drupal_id]
  );
  const comments = useMemo(
    () => comments6thEdition(node, commentsJsonapi),
    [isFetchingComments, commentsJsonapi, node]
  );

  return (
    <>
      <Navbar isSolution />
      <div className="bg-neutral-100 h-full min-h-screen">
        <div className="max-w-8xl mx-auto">
          <Menu
            chapterTerm={node.relationships.field_6th_edition_chapter}
            sections={sections}
            node={node}
            edition={EDITION_6TH}
          />
          <div className="lg:ml-[28rem] px-4">
            <Heading node={node} />
            <Answer node={node} />
            <div className="flex justify-center my-8">
              <VideoArea
                node={node}
                chapterNodes={
                  node.relationships.field_6th_edition_chapter.relationships
                    .node__6th_edition_video
                }
              />
            </div>
            {!currentUser?.is_authenticated &&
              !node.field_sample &&
              node.field_transcript && (
                <div className="my-8">
                  <SectionTitle
                    backgroundStyle="white"
                    title="Video Transcript"
                  />
                  <p
                    dangerouslySetInnerHTML={{ __html: node.field_transcript }}
                  ></p>
                </div>
              )}
            {comments?.length > 0 && (
              <div id="section-heading--comments" className="my-8">
                <SectionTitle backgroundStyle="white" title="Comments" />
              </div>
            )}
            {comments?.map((comment) => {
              return (
                <CommentParent
                  key={comment.parent.drupal_internal__cid}
                  comment={comment}
                  contentType="6th_edition_video"
                />
              );
            })}
            <div className="my-8">
              <CommentCreateForm contentType="6th_edition_video" node={node} />
            </div>
          </div>
          <div className="lg:ml-[28rem] lg:mr-4">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Solution6thEdition;

export const Head = ({ location, data }: HeadProps<Solution6thEditionData>) => {
  const node = data.node6thEditionVideo;
  const { webpage6thEdition, videoDescription } = seoUtils;

  return (
    <>
      <Seo
        title={`Giancoli 6th Edition, Chapter ${node.relationships.field_6th_edition_chapter.field_chapter_number}, Problem ${node.field_problem}`}
        pathname={location.pathname}
        description={videoDescription(node)}
      />
      <JsonLd item={webpage6thEdition(node, location.pathname)} />
    </>
  );
};

export const query = graphql`
  query getSolution6thEdition($path__alias: String) {
    node6thEditionVideo(path: { alias: { eq: $path__alias } }) {
      drupal_internal__nid
      field_s3_filename
      field_s3_folder
      field_sample
      field_transcript
      created(formatString: "X")
      field_duration
      title
      drupal_id
      fields {
        fieldQuickAnswerProcessedKatex
      }
      field_problem
      comment_node_6th_edition_video {
        cid
        last_comment_timestamp
        comment_count
      }
      relationships {
        comment__comment_node_6th_edition_video {
          drupal_id
          relationships {
            uid {
              name
              drupal_internal__uid
            }
            entity_id {
              drupal_id
            }
          }
          changed(formatString: "X")
          comment_body {
            processed
            value
          }
          fields {
            commentBodyProcessedKatex
          }
          pid {
            drupal_internal__target_id
          }
          drupal_internal__cid
          thread
        }
        field_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 640, placeholder: TRACED_SVG)
            }
          }
        }
        videoThumbnailFixed: field_thumbnail {
          localFile {
            childImageSharp {
              resize(width: 640) {
                src
              }
            }
          }
        }
        field_6th_edition_chapter {
          drupal_internal__tid
          field_chapter_number
          name
          field_thumbnail {
            alt
          }
          description {
            value
          }
          relationships {
            solutionMenuThumbnail: field_thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 64, height: 64)
                }
              }
            }
            node__6th_edition_video {
              field_sample
              title
              field_problem
              path {
                alias
              }
            }
            taxonomy_term__6th_edition_section {
              field_section_name
              field_section_number
              drupal_internal__tid
              weight
              relationships {
                node__6th_edition_video {
                  drupal_internal__nid
                  field_duration
                  field_problem
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
